<template>
  <div class="dashboard__navigation-container">
    <div v-for="item in getItemsByRole()" :key="item.index">
      <router-link :to="item.url" class="dashboard__navigation-item">
        <svg
          class="dashboard__navigation-itemicon"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          v-html="item.icon"
        />
        <span class="dashboard__navigation-item-text">{{ item.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  setup() {
    const items = [
      {
        title: "Dashboard",
        role: "board",
        url: "/dashboard",
        icon:
          '<path d="m3.5 18.49 6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"></path>',
      },
      {
        title: "Nieuws",
        role: "board",
        url: "/dashboard/nieuws",
        icon:
          '<path d="m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z"></path>',
      },
      {
        title: "Leden",
        role: "board",
        url: "/dashboard/leden",
        icon:
          '<path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>',
      },
      /* {
        title: "Personeel",
        role: "board",
        url: "/dashboard/personeel",
        icon:
          '<path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>',
      }, */
      /* {
        title: "Tijdsloten",
        role: "board",
        url: "/tijdsloten/overzicht",
        icon:
          '<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>',
      }, */
      {
        title: "Mijn OIJV",
        role: "member",
        url: "/mijn-account",
        icon: `<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>`,
      },
      {
        title: "Scannen",
        role: "board",
        url: "/dashboard/scannen",
        icon: `<path d="M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z"></path>`
      },
      {
        title: 'Tickets',
        role: 'member',
        url: '/dashboard/tickets',
        icon: `<path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"></path>`
      }
      /* {
        title: "Reserveren",
        role: "member",
        url: "/reserveren",
        icon:
          '<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>',
      }, */
    ];

    const userRoles = computed(() => {
      const json = document.cookie.split("; ").reduce((r, v) => {
          const parts = v.split("=");
          return parts[0] === "roles" ? decodeURIComponent(parts[1]) : r;
        }, "");

        if (!json) return;

        return JSON.parse(json);
    });

    function getItemsByRole() {
      return items.filter((item) => userRoles.value.includes(item.role));
    }

    return {
      items,
      getItemsByRole,
    };
  },
};
</script>
