<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container" style="margin-bottom: 3rem">
        <h1 class="dashboard__title">Mijn Tickets</h1>
        <div class="dashboard__tile-11" style="margin: 1.5rem 0">
          <div class="dashboard__item-container">
            <h2 class="dashboard__tile-title">
              {{ user.firstname }} {{ user.lastname }}
            </h2>
            <img :src="user.qr" />
          </div>
          <div
            class="dashboard__item-container"
            v-for="user in user.submembers"
            :key="user._id"
          >
            <h2 class="dashboard__tile-title">
              {{ user.firstname }} {{ user.lastname }}
            </h2>
            <img :src="user.qr" />
          </div>
        </div>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import qr from "qrcode";
import axios from "axios";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";
import SubmemberPopup from "@/components/SubmemberPopup.vue";

function createQrImage(object) {
  return qr.toDataURL(JSON.stringify(object), {
    width: 200,
    
  });
}

export default {
  components: {
    NavigationBar,
    DashboardNavigation,
    FooterComponent,
  },
  setup() {
    const user = ref({});

    const getUser = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/account-details",
          method: "GET",
          withCredentials: true,
        });

        user.value = data;

        user.value.qr = await createQrImage({ id: user.value._id, type: 'member' });
        user.value.submembers.map(async user => {
            user.qr = await createQrImage({ id: user._id, type: 'member' });
        })
      } catch (e) {
        console.error(e);
        /* if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        } */
      }
    };

    getUser();

    return {
      user,
      createQrImage
    };
  },
};
</script>