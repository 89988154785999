<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <form
      class="dashboard__container"
      method="POST"
      v-on:submit.prevent="updateUser"
    >
      <dashboard-navigation />
      <div class="dashboard__main-container">
        <h1 class="dashboard__title">Account wijzigen</h1>
        <div class="login__errors" v-if="errors && errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <div
          class="dashboard__item-container"
          style="margin: 1.5rem 0; padding-bottom: 1rem"
        >
          <div class="setup__formgrid setup__formgrid-11">
            <div>
              <label class="label label-required">Voornaam</label>
              <input
                class="input input__text input__fw"
                type="text"
                autocomplete="false"
                placeholder="voornaam"
                v-model="user.firstname"
                required
                pattern="[a-zA-Z\.\-_ ]{1,20}"
              />
            </div>
            <div>
              <label class="label label-required">Achternaam</label>
              <input
                class="input input__text input__fw"
                type="text"
                autocomplete="false"
                placeholder="achternaam"
                v-model="user.lastname"
                required
                pattern="[a-zA-Z\-_ ]{3,30}"
              />
            </div>
          </div>
          <div class="setup__formgrid setup__formgrid-11">
            <div class="setup__formgrid setup__formgrid-11">
              <div>
                <label class="label label-required">Geboortedatum</label>
                <input
                  class="input input__date input__fw input__date-nocalander"
                  :type="dateInputSupported ? 'date' : 'text'"
                  autocomplete="bday"
                  placeholder="DD-MM-JJJJ"
                  v-model="user.parsedBirthdate"
                  required
                  pattern="[0-9]{2}-[0-9]{2}-([19]|[20]){2}[0-9]{2}"
                />
              </div>
              <div>
                <label class="label label-required">Geslacht</label>
                <select
                  class="input input__select input__fw"
                  v-model="user.gender"
                  autocomplete="false"
                  required
                >
                  <option value="male">Man</option>
                  <option value="female">Vrouw</option>
                </select>
              </div>
            </div>
          </div>
          <br />
          <div class="setup__formgrid setup__formgrid-11">
            <div class="setup__formgrid setup__formgrid-31">
              <div>
                <label class="label label-required">Straatnaam</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  autocomplete="false"
                  placeholder="straatnaam"
                  v-model="user.streetname"
                  required
                  pattern="[a-zA-Z.\-'_ ]{2,50}"
                />
              </div>
              <div>
                <label class="label label-required">Huisnummer</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  autocomplete="false"
                  placeholder="huisnummer"
                  v-model="user.housenumber"
                  required
                  pattern="[0-9]{1,3}[A-Za-z0-9\-]{0,6}"
                />
              </div>
            </div>
            <div class="setup__formgrid setup__formgrid-13">
              <div>
                <label class="label label-required">Postcode</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  autocomplete="false"
                  placeholder="postcode"
                  v-model="user.postalcode"
                  required
                  maxlength="6"
                  pattern="[0-9]{4}[a-zA-Z]{2}"
                />
              </div>
              <div>
                <label class="label label-required">Plaatsnaam</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  autocomplete="false"
                  placeholder="plaatsnaam"
                  v-model="user.place"
                  required
                  pattern="[a-zA-Z_ ]{3,30}"
                />
              </div>
            </div>
          </div>
          <br />
          <div class="setup__formgrid setup__formgrid-11">
            <div>
              <label class="label">Emailadres</label>
              <input
                class="input input__text input__fw"
                type="text"
                disabled
                v-model="user.email"
              />
            </div>
            <div>
              <label class="label label-required">Telefoonummer</label>
              <input
                class="input input__text input__fw"
                type="tel"
                autocomplete="false"
                placeholder="telefoonummer"
                v-model="user.phone"
                required
                pattern="(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)"
              />
            </div>
          </div>
          <div class="members__toolbar">
            <router-link
              :to="'/mijn-account'"
              class="button button input__button"
              >Annuleren</router-link
            >
            <button
              class="button input__button submit__buttons-step"
              type="submit"
            >
              Opslaan
            </button>
          </div>
        </div>
      </div>
    </form>
    <footer-component />
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import date from "date-and-time";
import { useRouter } from "vue-router";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";

export default {
  components: { DashboardNavigation, NavigationBar, FooterComponent },
  setup() {
    const errors = ref([]);
    const router = useRouter();
    const user = ref({
      submembers: [],
    });
    const dateInputSupported = checkDateInputIsSupported();

    const getUser = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/account-details",
          method: "GET",
          withCredentials: true,
        });

        user.value = { ...user.value, ...data };

        user.value.parsedBirthdate = data.birthdate && date.format(
          new Date(data.birthdate),
          dateInputSupported ? "YYYY-MM-DD" : "DD-MM-YYYY"
        );

        errors.value = [];
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const updateUser = async (event) => {
      try {
        const form = event.target;

        if (!form.checkValidity()) {
          return form.reportValidity();
        }

        user.value.birthdate = parseStringToDate(
          user.value.parsedBirthdate,
          dateInputSupported ? "yyyy-MM-dd" : "dd-MM-yyyy"
        );

        await axios({
          url: "/api/my-account/account-details",
          method: "PUT",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
          data: {
            firstname: user.value.firstname,
            lastname: user.value.lastname,
            birthdate: user.value.birthdate,
            gender: user.value.gender,
            streetname: user.value.streetname,
            housenumber: user.value.housenumber,
            place: user.value.place,
            postalcode: user.value.postalcode,
            phone: user.value.phone,
          },
        });

        errors.value = [];

        return router.push("/mijn-account");
        // TODO: notification
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    getUser();

    return {
      errors,
      user,
      updateUser,
      dateInputSupported,
    };
  },
};

function checkDateInputIsSupported() {
  const isOpera =
    !!window.opr || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
  const isFirefox = typeof InstallTrigger !== "undefined";
  const isChrome = navigator.userAgentData?.brands?.some(
    (b) => b.brand === "Google Chrome"
  );

  return isOpera || isFirefox || isChrome;
}

function parseStringToDate(string = "", format) {
  try {
    switch (format) {
      case "yyyy-MM-dd": {
        const day = string.split("-")[2];
        const month = string.split("-")[1];
        const year = string.split("-")[0];
        return new Date(`${year}-${month}-${day}`);
      }

      case "dd-MM-yyyy": {
        const day = string.split("-")[0];
        const month = string.split("-")[1];
        const year = string.split("-")[2];
        return new Date(`${year}-${month}-${day}`);
      }

      default:
        throw new Error('Format invalid')
    }
  } catch (e) {
    console.error(e);
  }
}
</script>