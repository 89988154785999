<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <h1 class="dashboard__title">Dashboard</h1>
        <div class="dashboard__tile-11" style="margin: 1.5rem 0">
          <member-count />
          <div class="dashboard__item-container">
            <h2 class="dashboard__tile-title">
              Contributie {{ totalContributionSeason }}
            </h2>
            <span class="dashboard__tile-amount"
              >€ {{ parseCosts(totalContributionAmount) }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";

import axios from "axios";
import { ref } from "vue";
import MemberCount from '../../components/MemberCount.vue';
export default {
  components: { NavigationBar, FooterComponent, DashboardNavigation, MemberCount },
  setup() {
    const errors = ref([]);
    const totalContributionSeason = ref("");
    const totalContributionAmount = ref(0);

    const getTotalContribution = async () => {
      try {
        const { data } = await axios({
          url: `/api/contribution/total-current-season`,
          method: "GET",
        });

        totalContributionSeason.value = data.season;
        totalContributionAmount.value = data.amount;
      } catch (e) {
        console.error(e);

        // TODO: handleError(e);
      }
    };

    const parseCosts = (costs) => {
      if (!costs) return;
      return Number(costs).toFixed(2).replace(/\./g, ",");
    };

    getTotalContribution();

    return {
      errors,
      totalContributionSeason,
      totalContributionAmount,
      parseCosts,
    };
  },
};
</script>
