import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import MyOIJVValidatePayment from '@/views/MyOIJVValidatePayment.vue';
import MyOIJVSetup from '@/views/MyOIJVSetup.vue';
import MyOIJV from '@/views/MyOIJV.vue';
import MyOIJVUpdate from '@/views/MyOIJVUpdate.vue';
import Dashboard from '@/views/Board/Dashboard.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import PrivacyStatement from '@/views/PrivacyStatement.vue';
import Timeslot from '@/views/Timeslot.vue';
import TimeslotManagement from '@/views/TimeslotManagement.vue';
import TimeslotView from '@/views/TimeslotView.vue';
import ScanTicketView from '@/views/Board/ScanTicketView.vue';
import TicketsView from '@/views/TicketsView.vue';

// import Covid from "@/views/Covid.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
  },
  {
    path: '/lidmaatschap',
    name: 'Membership',
    component: () => import(/* webpackChunkName: "Membership" */ '@/views/Membership.vue')
  },
  {
    path: '/bestuur',
    name: 'BoardMembers',
    component: () => import(/* webpackChunkName: "Board" */ '@/views/BoardMembers.vue')
  },
  {
    path: '/nieuws',
    name: 'News',
    component: () => import(/* webpackChunkName: "News" */ '@/views/News.vue')
  },
  {
    path: '/nieuws/:slug',
    name: 'NewsItem',
    component: () => import(/* webpackChunkName: "News" */ '@/views/NewsItem.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '@/views/Contact.vue')
  },
  {
    path: '/oijv-en-veiligheid',
    name: 'OIJV en veiligheid',
    component: () => import(/* webpackChunkName: "VOG" */ '@/views/VOG.vue')
  },
  {
    path: '/inloggen',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/SignIn.vue')
  },
  {
    path: '/wachtwoord-instellen',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue')
  },
  {
    path: '/inschrijven',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "SignUp" */ '@/views/SignUp.vue')
  },
  {
    path: '/privacy-verklaring',
    name: 'PrivacyStatement',
    component: PrivacyStatement
  },
  {
    path: '/mijn-account/setup',
    name: 'MyOIJVSetup',
    component: MyOIJVSetup
  },
  {
    path: '/mijn-account/betaling-verwerken',
    name: 'MyOIJVValidatePayment',
    component: MyOIJVValidatePayment
  },
  {
    path: '/mijn-account',
    name: 'MyOIJV',
    component: MyOIJV
  },
  {
    path: '/mijn-account/wijzigen',
    name: 'MyOIJVUpdate',
    component: MyOIJVUpdate
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/dashboard/leden',
    name: 'Members',
    component: () => import(/* webpackChunkName: "Members" */ '@/views/Board/Members.vue')
  },
  {
    path: '/dashboard/leden/:id',
    name: 'MembersItem',
    component: () => import(/* webpackChunkName: "MembersItem" */ '@/views/Board/MembersItem.vue')
  },
  {
    path: '/dashboard/nieuws',
    name: 'DashboardNews',
    component: () => import(/* webpackChunkName: "DashboardNews" */ '@/views/Board/News.vue')
  },
  {
    path: '/dashboard/nieuws/:id',
    name: 'DashboardNewsItem',
    component: () => import(/* webpackChunkName: "DashboardNewsItem" */ '@/views/Board/NewsItem.vue')
  },
  {
    path: '/reserveren',
    name: 'Timeslot',
    component: Timeslot
  },
  {
    path: '/dashboard/tijdsloten/overzicht',
    name: 'TimeslotManagement',
    component: TimeslotManagement
  },
  /* {
    path: '/dashboard/tijdsloten/toevoegen',
    name: 'TimeslotAdd',
    component: TimeslotAddEdit
  }, */
  {
    path: '/dashboard/tijdsloten/:id',
    name: 'TimeslotView',
    component: TimeslotView
  },
  {
    path: '/dashboard/scannen',
    name: 'ScanTicket',
    component: ScanTicketView
  },
  {
    path: '/dashboard/tickets',
    name: 'TicketsView',
    component: TicketsView
  },
  /* {
    path: '/covid-19',
    name: 'Covid',
    component: Covid
  }, */
  {
    path: "/:pathMatch(.*)*",
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

trackRouter(router);

export default router
