<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <h1 class="dashboard__title">Tijdslot</h1>
        <div class="dashboard__toolbar">
          <h2
            class="dashboard__subtitle dashboard__myoijv-details-submembertitle"
            style="margin: 0"
          >
            Leden
          </h2>
          <router-link
            class="dashboard__toolbar-button button button input__button"
            style="margin-left: auto"
            :to="`/tijdsloten/${id}/bewerken`"
            >Bewerken</router-link
          >
          <a
            style="margin-left: 1rem"
            target="_blank"
            :href="`/tijdsloten/${id}/printen`"
            class="dashboard__toolbar-button button button input__button"
          >
            Printen
          </a>
        </div>
        <table class="timeslot__members table">
          <thead class="table__head">
            <tr>
              <th class="table__column table__head-column" style="width: 4.3rem;">Aanwezig</th>
              <th class="table__column table__head-column">Achternaam</th>
              <th class="table__column table__head-column">Voornaam</th>
              <th class="table__column table__head-column">Geboortedatum</th>
            </tr>
          </thead>
          <tbody class="table__body">
            <tr
              v-for="member in timeslot.checkedInMembers"
              :key="member._id"
              class="timeslot__members-item"
            >
              <td class="table__column table__body-column" style="text-align: center;">
                <label class="checkbox">
                  <input
                    class="checkbox__input"
                    type="checkbox"
                    autocomplete="false"
                  />
                  <span class="checkbox__span"><span></span></span>
                </label>
              </td>
              <td class="table__column table__body-column" style="width: 12rem;">
                {{ member.lastname }}
              </td>
              <td class="table__column table__body-column" style="width: 10rem;">
                {{ member.firstname }}
              </td>
              <td class="table__column table__body-column">
                {{ date.format(member.birthdate, "DD-MM-YYYY") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from "vue";
import axios from "axios";
import date from "date-and-time";
import { io } from "socket.io-client";
import { useRoute } from "vue-router";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";

export default {
  components: { NavigationBar, FooterComponent, DashboardNavigation },
  setup() {
    const route = useRoute();
    const errors = ref([]);
    const timeslot = ref([]);
    const socket = io({ transports: ["websocket"], upgrade: false });
    const id = route.params.id;

    getTimeslot();

    onMounted(() => {
      try {
        socket.connect();
        socket.on("present", () => {
          getTimeslot();
        });
      } catch (e) {
        console.log(e);
      }
    });

    onUnmounted(() => {
      socket.close();
    });

    async function getTimeslot() {
      try {
        const resp = await axios({
          url: `/api/timeslots/${id}`,
          method: "GET",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        timeslot.value = resp.data;

        errors.value = [];
      } catch (e) {
        handleError(e);
      }
    }

    function handleError(e) {
      if (e.response && e.response.data && e.response.data.errors) {
        errors.value = e &&
          e.response &&
          e.response.data &&
          e.response.data.errors && [...new Set(e.response.data.errors)];

        return;
      }

      fetch("/api/error", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          error: {
            string: e.toString(),
            msg: e.message,
            fileName: e.fileName,
            lineNumber: e.lineNumber,
          },
        }),
      });
    }

    return {
      errors,
      timeslot,
      id,
    };
  },
};
</script>
