<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container" style="margin-bottom: 3rem">
        <h1 class="dashboard__title">Mijn OIJV</h1>
        <div class="dashboard__toolbar">
          <h3
            style="margin: 0"
            class="dashboard__myoijv-details-submembertitle"
          >
            Accountgegevens
          </h3>
          <router-link
            class="dashboard__toolbar-button button button input__button"
            :to="'/mijn-account/wijzigen'"
            style="margin-left: auto"
            >Wijzigen</router-link
          >
        </div>
        <div class="dashboard__myoijv-details">
          <div class="dashboard__myoijv-details-submember-item">
            <h2 class="dashboard__subtitle">
              {{ user.firstname }} {{ user.lastname }}
            </h2>
            <span class="dashboard__myoijv-details">{{ user.email }}</span>
            <span class="dashboard__myoijv-details">{{
              date.format(new Date(user.birthdate), "DD-MM-YYYY")
            }}</span>
            <span class="dashboard__myoijv-details">{{
              user.gender == "male" ? "man" : "vrouw"
            }}</span>
            <span class="dashboard__myoijv-details"
              >{{ user.streetname }} {{ user.housenumber }}, {{ user.place }}
              {{ user.postalcode }}</span
            >
            <span class="dashboard__myoijv-details">{{ user.phone }}</span>
          </div>
          <br />
          <div class="dashboard__toolbar">
            <h3
              style="margin: 0"
              class="dashboard__myoijv-details-submembertitle"
            >
              Familieleden
            </h3>
            <button
              @click="
                selectedSubmember = {};
                displayAddUpdateSubmemberPopup = true;
              "
              class="dashboard__toolbar-button button button input__button"
              style="margin-left: auto"
            >
              Familielid toevoegen
            </button>
          </div>
          <span
            v-if="!user.submembers || user.submembers.length < 1"
            style="display: block; text-align: center; margin: 1rem auto"
            >Geen familieleden toegevoegd</span
          >
          <div class="dashboard__myoijv-details-submember">
            <div
              class="dashboard__myoijv-details-submember-item"
              v-for="submember in user.submembers"
              :key="submember._id"
              style="display: flex"
            >
              <div style="flex: 1">
                <span class="dashboard__myoijv-details" style="margin: 0"
                  >{{ submember.firstname }} {{ submember.lastname }}</span
                >
                <span class="dashboard__myoijv-details">
                  {{
                    date.format(new Date(submember.birthdate), "DD-MM-YYYY")
                  }}</span
                >
                <span class="dashboard__myoijv-details">
                  {{ submember.gender == "male" ? "man" : "vrouw" }}</span
                >
              </div>
              <div class="submembers__buttons-container">
                <button
                  class="button__transparent submembers__button"
                  @click="
                    selectedSubmember = submember;
                    displayAddUpdateSubmemberPopup = true;
                  "
                >
                  <svg
                    class="submembers__button-icon"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                    ></path>
                  </svg>
                </button>
                <button
                  class="button__transparent submembers__button"
                  @click="
                    selectedSubmember = submember;
                    displayRemoveSubmemberPopup = true;
                  "
                >
                  <svg
                    class="submembers__button-icon"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="dashboard__toolbar">
          <h3
            style="margin: 0"
            class="dashboard__myoijv-details-submembertitle"
          >
            Contributie
          </h3>
        </div>
        <div>
          <div
            v-if="contributionPaid.seasonSlug == '2023-2024'"
            class="dashboard__myoijv-details-submember-item"
            style="margin: 1rem 0"
          >
            <span
              class="dashboard__myoijv-details"
              style="display: flex; align-items: center"
            >
              <svg
                v-if="!contributionPaid.toPay?.totalContribution"
                style="width: 1.5rem; margin-right: 1rem"
                class="payment-validation__icon-success"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                ></path>
              </svg>
              <svg
                v-if="contributionPaid.toPay?.totalContribution"
                style="width: 1.5rem; margin-right: 1rem"
                class="payment-validation__icon-failed"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                tabindex="-1"
                title="Close"
              >
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                ></path></svg
              >{{ contributionPaid.seasonSlug }} Betaald: €{{
                parseCosts(contributionPaid.paid)
              }}.&nbsp;
              <span v-if="!contributionPaid.toPay?.totalContribution"
                >Contributie volledig betaald
              </span>
              <span
                v-if="
                  contributionPaid.toPay?.totalContribution ||
                  contributionPaid.toPay?.registrationFee
                "
                >Nog te betalen: €{{
                  parseCosts(
                    contributionPaid.toPay?.totalContribution +
                      contributionPaid.toPay?.registrationFee -
                      contributionPaid.paid
                  )
                }}
              </span>
              <button
                style="margin-left: auto;"
                class="dashboard__toolbar-button button button input__button"
                @click="createPayment()"
                v-if="
                  contributionPaid.toPay?.totalContribution ||
                  contributionPaid.toPay?.registrationFee
                "
              >
                Contributie betalen
              </button>
            </span>
          </div>
        </div>
        <br />
        <div class="dashboard__toolbar">
          <h3
            style="margin: 0"
            class="dashboard__myoijv-details-submembertitle"
          >
            Betaalmethode
          </h3>
        </div>
        <div class="dashboard__myoijv-details-submember-item">
          <div>
            <span
              class="dashboard__myoijv-details"
              style="display: flex; align-items: center"
            >
              <svg
                v-if="paymentMethod.status == 'valid' || user.hasOldIncasso"
                style="width: 1.5rem; margin-right: 1rem"
                class="payment-validation__icon-success"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                ></path>
              </svg>
              <svg
                v-if="paymentMethod.status != 'valid' && !user.hasOldIncasso"
                style="width: 1.5rem; margin-right: 1rem"
                class="payment-validation__icon-failed"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                tabindex="-1"
                title="Close"
              >
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                ></path>
              </svg>
              <span v-if="user.hasOldIncasso">Automatische incasso </span>
              <span v-if="paymentMethod.iban"
                >{{ paymentMethod.iban }} - Automatische incasso</span
              >
              <span
                v-if="paymentMethod.status != 'valid' && !user.hasOldIncasso"
                >Geen geldige incassomachtiging</span
              >
            </span>
          </div>
        </div>
        <br />
        <br />
        <div style="display: flex" @click="displayStopMembership = true">
          <button class="button input__button" style="margin-left: auto">
            Lidmaatschap opzeggen
          </button>
        </div>
      </div>
    </div>
    <footer-component />
    <submember-popup
      v-if="displayAddUpdateSubmemberPopup"
      v-model:visible="displayAddUpdateSubmemberPopup"
      v-model:selectedSubmember="selectedSubmember"
      @update-submember="
        () => {
          getUser();
          getContributionPaid();
        }
      "
    />
    <div v-if="displayRemoveSubmemberPopup" class="popup__wrapper">
      <div class="popup__box" style="max-width: 30rem">
        <h2 class="popup__title">Familielid verwijderen</h2>
        <p class="popup__text">
          Weet u zeker dat u dit familielid wilt verwijderen?
        </p>
        <div
          style="display: flex; flex-direction: row; justify-content: center"
        >
          <button
            class="button button input__button"
            @click="displayRemoveSubmemberPopup = false"
          >
            Annuleren
          </button>
          <button
            @click="deleteSubmember"
            style="margin-left: 0.5rem"
            class="button input__button submit__buttons-step"
            type="submit"
          >
            Verwijderen
          </button>
        </div>
      </div>
    </div>
    <div v-if="displayStopMembership" class="popup__wrapper">
      <div class="popup__box" style="max-width: 30rem">
        <h2 class="popup__title">Lidmaatschap opzeggen</h2>
        <p class="popup__text">
          Weet u zeker dat u het lidmaatschap wilt opzeggen?<br />
          U kunt deze actie niet terugdraaien!<br />
          <br />
          Voer uw wachtwoord in om door te gaan
        </p>
        <div>
          <input
            class="input input__text input__fw"
            type="password"
            autocomplete="false"
            placeholder="wachtwoord"
            v-model="confirmPassword"
            required
          />
        </div>
        <br />
        <div
          style="display: flex; flex-direction: row; justify-content: center"
        >
          <button
            class="button button input__button"
            @click="displayStopMembership = false"
          >
            Annuleren
          </button>
          <button
            @click="deleteAccount"
            style="margin-left: 0.5rem"
            class="button input__button submit__buttons-step"
            type="submit"
          >
            Doorgaan
          </button>
        </div>
      </div>
    </div>
    <!-- <pay-contribution-popup v-if="contributionPaid.toPay && !user.hasOldIncasso" /> -->
  </div>
</template>

<script>
import axios from "axios";
import date from "date-and-time";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";
import SubmemberPopup from "@/components/SubmemberPopup.vue";

import { ref } from "vue";
import { useRouter } from "vue-router";
import PayContributionPopup from "../components/PayContributionPopup.vue";

export default {
  components: {
    NavigationBar,
    DashboardNavigation,
    SubmemberPopup,
    FooterComponent,
    PayContributionPopup,
  },
  setup() {
    const router = useRouter();
    const errors = ref([]);
    const user = ref({});
    const contributionPaid = ref([]);
    const displayAddUpdateSubmemberPopup = ref(false);
    const selectedSubmember = ref({});
    const displayRemoveSubmemberPopup = ref(false);
    const paymentMethod = ref({});
    const displayStopMembership = ref(false);
    const confirmPassword = ref("");

    const getUser = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/account-details",
          method: "GET",
          withCredentials: true,
        });

        user.value = data;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const deleteSubmember = async () => {
      try {
        const id = selectedSubmember.value._id;

        await axios({
          url: `/api/my-account/submembers/${id}`,
          method: "DELETE",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        errors.value = [];

        displayRemoveSubmemberPopup.value = false;

        user.value.submembers = user.value.submembers.filter(
          (submember) => submember._id !== id
        );

        // TODO: notification
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const getContributionPaid = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/contribution-paid",
          method: "GET",
          withCredentials: true,
        });

        contributionPaid.value = data;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const getPaymentMethod = async () => {
      try {
        const { data } = await axios({
          url: `/api/my-account/payment-method`,
          method: "GET",
          withCredentials: true,
        });

        paymentMethod.value = data;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const setPaymentMethod = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/payment-method",
          method: "PUT",
          withCredentials: true,
        });

        window.location = data.redirectUrl;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const deleteAccount = async () => {
      try {
        await axios({
          url: `/api/my-account`,
          method: "DELETE",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
          data: {
            password: confirmPassword.value,
          },
        });

        errors.value = [];

        return router.push("/");

        // return router.push("/bedankt");
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const createPayment = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/payment",
          method: "POST",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        window.location = data.redirectUrl;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    function parseCosts(costs) {
      if (!String(costs)) return;
      return Number(costs).toFixed(2).replace(/\./g, ",");
    }

    getUser();
    getContributionPaid();
    getPaymentMethod();

    return {
      getUser,
      errors,
      user,
      date,
      parseCosts,
      displayAddUpdateSubmemberPopup,
      SubmemberPopup,
      selectedSubmember,
      deleteSubmember,
      displayRemoveSubmemberPopup,
      contributionPaid,
      paymentMethod,
      setPaymentMethod,
      displayStopMembership,
      confirmPassword,
      deleteAccount,
      createPayment,
    };
  },
};
</script>
