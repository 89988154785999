<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="content__centered content__centered-setup">
      <div class="setup__container content__centered-container">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <div class="setup__steps">
          <div
            class="setup__step"
            :class="{ selected: currentView == 'Account' }"
          >
            <span class="setup__step-text">Stap 1: Account</span>
          </div>
          <div
            class="setup__step"
            :class="{ selected: currentView == 'Submembers' }"
          >
            <span class="setup__step-text">Stap 2: Familieleden</span>
          </div>
          <div
            v-if="!user.hasOldIncasso"
            class="setup__step"
            :class="{ selected: currentView == 'Payment' }"
          >
            <span class="setup__step-text">Stap 3: Betaling</span>
          </div>
        </div>
        <div class="setup__container-content">
          <form
            v-if="currentView == 'Account'"
            method="POST"
            v-on:submit.prevent="updateUser"
          >
            <div class="setup__formgrid setup__formgrid-11">
              <div>
                <label class="label label-required">Voornaam</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  autocomplete="given-name"
                  placeholder="voornaam"
                  v-model="user.firstname"
                  pattern="[a-zA-Z\.\-_ ]{2,20}"
                  required
                  autofocus
                />
              </div>
              <div>
                <label class="label label-required">Achternaam</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  autocomplete="family-name"
                  placeholder="achternaam"
                  v-model="user.lastname"
                  required
                  pattern="[a-zA-Z\-_ ]{3,30}"
                />
              </div>
            </div>
            <div class="setup__formgrid setup__formgrid-11">
              <div class="setup__formgrid setup__formgrid-11">
                <div>
                  <label class="label label-required">Geboortedatum</label>
                  <input
                    class="input input__date input__fw input__date-nocalander"
                    :type="dateInputSupported ? 'date' : 'text'"
                    autocomplete="bday"
                    placeholder="DD-MM-JJJJ"
                    v-model="user.parsedBirthdate"
                    required
                    pattern="[0-9]{2}-[0-9]{2}-([19]|[20]){2}[0-9]{2}"
                  />
                </div>
                <div>
                  <label class="label label-required">Geslacht</label>
                  <select
                    class="input input__select input__fw"
                    v-model="user.gender"
                    required
                    autocomplete="sex"
                  >
                    <option value="male">Man</option>
                    <option value="female">Vrouw</option>
                  </select>
                </div>
              </div>
            </div>
            <br />
            <div class="setup__formgrid setup__formgrid-11">
              <div class="setup__formgrid setup__formgrid-31">
                <div>
                  <label class="label label-required">Straatnaam</label>
                  <input
                    class="input input__text input__fw"
                    type="text"
                    autocomplete="street-address"
                    placeholder="straatnaam"
                    v-model="user.streetname"
                    required
                    pattern="[a-zA-Z.\-'_ ]{2,50}"
                  />
                </div>
                <div>
                  <label class="label label-required">Huisnummer</label>
                  <input
                    class="input input__text input__fw"
                    type="text"
                    autocomplete="address-line2"
                    placeholder="huisnummer"
                    v-model="user.housenumber"
                    required
                    pattern="[0-9]{1,3}[A-Za-z0-9\-]{0,6}"
                  />
                </div>
              </div>
              <div class="setup__formgrid setup__formgrid-13">
                <div>
                  <label class="label label-required">Postcode</label>
                  <input
                    class="input input__text input__fw"
                    type="text"
                    autocomplete="postal-code"
                    placeholder="postcode"
                    v-model="user.postalcode"
                    maxlength="6"
                    required
                    pattern="[0-9]{4}[a-zA-Z]{2}"
                  />
                </div>
                <div>
                  <label class="label label-required">Plaatsnaam</label>
                  <input
                    class="input input__text input__fw"
                    type="text"
                    autocomplete="city"
                    placeholder="plaatsnaam"
                    v-model="user.place"
                    required
                    pattern="[a-zA-Z_ ]{3,30}"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="setup__formgrid setup__formgrid-11">
              <div>
                <label class="label">Emailadres</label>
                <input
                  class="input input__text input__fw"
                  type="text"
                  disabled
                  v-model="user.email"
                />
              </div>
              <div>
                <label class="label label-required">Telefoonummer</label>
                <input
                  class="input input__text input__fw"
                  type="tel"
                  autocomplete="tel"
                  placeholder="telefoonummer"
                  v-model="user.phone"
                  required
                  pattern="(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)"
                />
              </div>
            </div>
            <div class="setup__buttons-container">
              <span></span>
              <button
                class="button input__button submit__buttons-step"
                type="submit"
              >
                Doorgaan
                <svg
                  class="submit__buttons-step-icon"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                  ></path>
                </svg>
              </button>
            </div>
          </form>
          <div v-if="currentView == 'Submembers'">
            <div class="dashboard__toolbar">
              <button
                @click="
                  selectedSubmember = {};
                  displayAddUpdateSubmemberPopup = true;
                "
                class="dashboard__toolbar-button button button input__button"
                style="margin-left: auto"
              >
                Familielid toevoegen
              </button>
            </div>
            <div style="min-height: 20rem">
              <span
                v-if="user.submembers?.length < 1"
                style="display: block; text-align: center; margin: 1rem auto"
                >Geen familieleden toegevoegd</span
              >
              <div class="dashboard__myoijv-details-submember">
                <div
                  class="dashboard__myoijv-details-submember-item"
                  v-for="submember in user.submembers"
                  :key="submember._id"
                  style="display: flex"
                >
                  <div style="flex: 1">
                    <span class="dashboard__myoijv-details" style="margin: 0"
                      >{{ submember.firstname }} {{ submember.lastname }}</span
                    >
                    <span class="dashboard__myoijv-details">
                      {{
                        date.format(new Date(submember.birthdate), "DD-MM-YYYY")
                      }}</span
                    >
                    <span class="dashboard__myoijv-details">
                      {{ submember.gender == "male" ? "man" : "vrouw" }}</span
                    >
                  </div>
                  <div class="submembers__buttons-container">
                    <button
                      class="button__transparent submembers__button"
                      @click="
                        selectedSubmember = submember;
                        displayAddUpdateSubmemberPopup = true;
                      "
                    >
                      <svg
                        class="submembers__button-icon"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                        ></path>
                      </svg>
                    </button>
                    <button
                      class="button__transparent submembers__button"
                      @click="
                        selectedSubmember = submember;
                        displayRemoveSubmemberPopup = true;
                      "
                    >
                      <svg
                        class="submembers__button-icon"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="setup__buttons-container">
              <button
                type="submit"
                @click="currentView = 'Account'"
                class="button input__button submit__buttons-step"
              >
                <svg
                  style="transform: rotate(180deg); margin-left: 0"
                  class="submit__buttons-step-icon"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                  ></path>
                </svg>
                Terug
              </button>
              <button
                type="button"
                @click="getMembershipCosts"
                class="button input__button submit__buttons-step"
              >
                Doorgaan
                <svg
                  class="submit__buttons-step-icon"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div v-if="currentView == 'Payment'">
            <div class="setup__costs-container">
              <div style="flex: 1">
                <div
                  class="setup__costs-grid"
                  v-if="contributionToPay.isFamilyMembership"
                >
                  <span class="setup__costs-griditem">Gezinslidmaatschap</span>
                  <span class="setup__costs-griditem setup__costs-price"
                    >€ {{ parseCosts(contribution?.family?.costs) }}</span
                  >
                </div>
                <div
                  class="setup__costs-grid"
                  v-if="
                    !contributionToPay.isFamilyMembership &&
                    contributionToPay.adultCount > 0
                  "
                >
                  <span class="setup__costs-griditem"
                    >Volwassenen ({{ contributionToPay.adultCount }}x)</span
                  >
                  <span class="setup__costs-griditem setup__costs-price"
                    >€
                    {{
                      parseCosts(
                        contributionToPay.adultCount *
                          contribution?.adult?.costs
                      )
                    }}</span
                  >
                </div>
                <div
                  class="setup__costs-grid"
                  v-if="
                    contributionToPay.isFamilyMembership &&
                    contributionToPay.adultCount > 2
                  "
                >
                  <span class="setup__costs-griditem"
                    >Volwassenen ({{ contributionToPay.adultCount - 2 }}x)</span
                  >
                  <span class="setup__costs-griditem setup__costs-price"
                    >€
                    {{
                      parseCosts(
                        (contributionToPay.adultCount - 2) *
                          contribution?.adult?.costs
                      )
                    }}</span
                  >
                </div>
                <div
                  class="setup__costs-grid"
                  v-if="
                    contributionToPay.childrenCount &&
                    !contributionToPay.isFamilyMembership
                  "
                >
                  <span class="setup__costs-griditem"
                    >Jeugd t/m 15 jaar ({{
                      contributionToPay.childrenCount
                    }}x)</span
                  >
                  <span
                    class="setup__costs-griditem setup__costs-price"
                    :class="{
                      'setup__costs-price-disabled':
                        contributionToPay.isFamilyMembership,
                    }"
                    >€
                    {{
                      parseCosts(
                        contributionToPay.childrenCount *
                          contribution?.child?.costs
                      )
                    }}</span
                  >
                </div>
                <div class="setup__costs-grid">
                  <span class="setup__costs-griditem">Inschrijfkosten</span>
                  <span class="setup__costs-griditem setup__costs-price"
                    >€ {{ parseCosts(contributionToPay.registrationFee) }}</span
                  >
                </div>
                <div class="setup__costs-grid">
                  <span class="setup__costs-griditem"></span>
                  <b class="setup__costs-griditem setup__costs-price"
                    >Totaal: € {{ parseCosts(contributionToPay.totalContribution + contributionToPay.registrationFee) }}</b
                  >
                </div>
              </div>
            </div>
            <div style="margin: 1.5rem 1rem 1rem">
              <label class="checkbox" style="margin-top: 0.2rem">
                <input
                  class="checkbox__input"
                  type="checkbox"
                  autocomplete="false"
                  v-model="acceptReccurent"
                  id="incasso"
                />
                <span class="checkbox__span"><span></span></span>
              </label>
              <label
                class="checkbox__label"
                for="incasso"
                style="width: 90%; line-height: 1.4rem"
                >Ik geef toestemming om in de toekomst door middel van een
                automatische incasso de contributie af te schrijven van mijn
                rekening.</label
              >
            </div>
            <div class="setup__buttons-container">
              <button
                class="button input__button submit__buttons-step"
                @click="
                  currentView = 'Submembers';
                  errors = [];
                "
              >
                <svg
                  style="transform: rotate(180deg); margin-left: 0"
                  class="submit__buttons-step-icon"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                  ></path>
                </svg>
                <span>Terug</span>
              </button>
              <button
                :disabled="!acceptReccurent"
                class="button input__button submit__buttons-step"
                @click="createPayment"
              >
                <span>Betalen</span>
                <svg
                  class="submit__buttons-step-icon"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
    <submember-popup
      v-if="displayAddUpdateSubmemberPopup"
      v-model:visible="displayAddUpdateSubmemberPopup"
      v-model:selectedSubmember="selectedSubmember"
      @update-submember="getUser"
    />
    <div v-if="displayRemoveSubmemberPopup" class="popup__wrapper">
      <div class="popup__box" style="max-width: 30rem">
        <h2 class="popup__title">Familielid verwijderen</h2>
        <p class="popup__text">
          Weet u zeker dat u dit familielid wilt verwijderen?
        </p>
        <div
          style="display: flex; flex-direction: row; justify-content: center"
        >
          <button
            class="button button input__button"
            @click="displayRemoveSubmemberPopup = false"
          >
            Annuleren
          </button>
          <button
            @click="deleteSubmember"
            style="margin-left: 0.5rem"
            class="button input__button submit__buttons-step"
            type="submit"
          >
            Verwijderen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import date from "date-and-time";
import { ref } from "vue";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import SubmemberPopup from "@/components/SubmemberPopup.vue";

export default {
  components: { NavigationBar, SubmemberPopup, FooterComponent },
  setup() {
    const dateInputSupported = checkDateInputIsSupported();
    const errors = ref([]);
    const user = ref({
      submembers: [],
    });
    const currentView = ref("Account");
    const acceptReccurent = ref(false);
    const selectedSubmember = ref(false);
    const displayRemoveSubmemberPopup = ref(false);
    const contribution = ref({});
    const contributionToPay = ref({});
    const displayAddUpdateSubmemberPopup = ref(false);

    const getUser = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/account-details",
          method: "GET",
          withCredentials: true,
        });

        user.value = { ...user.value, ...data };

        user.value.parsedBirthdate = data.birthdate && date.format(
          new Date(data.birthdate),
          dateInputSupported ? "YYYY-MM-DD" : "DD-MM-YYYY"
        );
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const updateUser = async (event) => {
      try {
        const form = event.target;

        if (!form.checkValidity()) {
          return form.reportValidity();
        }

        user.value.birthdate = parseStringToDate(
          user.value.parsedBirthdate,
          dateInputSupported ? "yyyy-MM-dd" : "dd-MM-yyyy"
        );

        await axios({
          url: "/api/my-account/account-details",
          method: "PUT",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
          data: {
            firstname: user.value.firstname,
            lastname: user.value.lastname,
            birthdate: user.value.birthdate,
            gender: user.value.gender,
            streetname: user.value.streetname,
            housenumber: user.value.housenumber,
            place: user.value.place,
            postalcode: user.value.postalcode,
            phone: user.value.phone,
          },
        });

        errors.value = [];
        currentView.value = "Submembers";
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const deleteSubmember = async () => {
      try {
        const id = selectedSubmember.value._id;

        await axios({
          url: `/api/my-account/submembers/${id}`,
          method: "DELETE",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        errors.value = [];

        displayRemoveSubmemberPopup.value = false;

        user.value.submembers = user.value.submembers.filter(
          (submember) => submember._id !== id
        );

        // TODO: notification
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const getContributionCosts = async () => {
      try {
        const { data } = await axios({
          url: "/api/contribution",
          method: "GET",
          withCredentials: true,
        });

        contribution.value = data;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    async function createPayment() {
      try {
        const { data } = await axios({
          url: "/api/my-account/payment",
          method: "POST",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        window.location = data.redirectUrl;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    }

    const getMembershipCosts = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/calculate-contribution",
          method: "GET",
          withCredentials: true,
        });

        contributionToPay.value = data;

        currentView.value = "Payment";
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    function parseCosts(costs) {
      if (!costs) return;
      return Number(costs).toFixed(2).replace(/\./g, ",");
    }

    getUser();
    getContributionCosts();

    return {
      date,
      errors,
      user,
      acceptReccurent,
      dateInputSupported,
      updateUser,
      parseCosts,
      displayRemoveSubmemberPopup,
      selectedSubmember,
      getUser,
      currentView,
      deleteSubmember,
      getMembershipCosts,
      createPayment,
      contribution,
      contributionToPay,
      displayAddUpdateSubmemberPopup,
    };
  },
};

function checkDateInputIsSupported() {
  const isOpera =
    !!window.opr || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
  const isFirefox = typeof InstallTrigger !== "undefined";
  const isChrome = navigator.userAgentData?.brands?.some(
    (b) => b.brand === "Google Chrome"
  );

  return isOpera || isFirefox || isChrome;
}

function parseStringToDate(string = "", format) {
  try {
    switch (format) {
      case "yyyy-MM-dd": {
        const day = string.split("-")[2];
        const month = string.split("-")[1];
        const year = string.split("-")[0];
        return new Date(`${year}-${month}-${day}`);
      }

      case "dd-MM-yyyy": {
        const day = string.split("-")[0];
        const month = string.split("-")[1];
        const year = string.split("-")[2];
        return new Date(`${year}-${month}-${day}`);
      }

      default:
        throw new Error("Format invalid");
    }
  } catch (e) {
    console.error(e);
  }
}
</script>