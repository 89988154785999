<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container">
        <h1 class="dashboard__title">Scannen</h1>
        <qr-scanner />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";
import QrScanner from "@/components/QrScanner.vue";

export default {
  components: {
    NavigationBar,
    FooterComponent,
    DashboardNavigation,
    QrScanner
  },
  setup() {

  }
};
</script>