<template>
    <div class="footer">
        <span class="footer__text">© {{ new Date().getFullYear() }} No Limit Software</span>
    </div>
</template>

<script>
export default {
    setup() {
        return {

        }
    }
}
</script>