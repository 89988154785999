<template>
  <header class="navigation__bar">
    <div class="navigation__bar-maxwidth">
      <router-link
        class="navigation__bar-logowrapper"
        :to="'/'"
        aria-label="OIJV logo"
      >
        <svg
          class="navigation__bar-logo"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1999 1612.9"
          style="enable-background: new 0 0 1999 1612.9"
          xml:space="preserve"
        >
          <path
            d="M1528.9,967.8c34.6-56.1,69.1-110,101.4-165.2c32.6-55.8,59.9-114,75.3-180h-248.2c20.7-75.7,38.1-149.6,32-229.2h-178.9
            c25.9-211.9-139-342.7-276.1-352.9c-75.9-5.7-145.8,11.9-206.8,57.1C728.2,171.2,690.3,272.7,703.1,396H529.2
            c-4.1,82.9,17.4,157.2,60.6,229.5H361.5c10.7,67,36.5,125.9,67.3,182.1c30.7,55.8,66.3,108.8,99.4,162.6c-34.8,0-71.8-0.8-108.8,0.2
            c-32.3,0.8-56.8-12-75.1-37.7c-15.4-21.7-31-43.3-44.7-66c-7.4-12.2-15.6-15.8-29.4-15.5c-42,0.9-84,0.3-127.6,0.3
            c0-11.6,0.1-20.4-0.1-29.2c-0.4-23.9-13.2-36.7-37-37.2c-10.5-0.2-21.1-0.5-31.6-0.5c-23.7-0.1-35.3,10.6-34.3,34.3
            c1.4,33.1,2.2,66.6,7.4,99.2c14.9,93,59.7,167.2,144,213.7c65.3,36,136,53.5,210.1,54.6c102.5,1.6,205.1,0.9,307.6,0.6
            c13.1,0,23.4,2.7,32.6,12.3c38.9,41,79.8,80.3,116.8,122.8c71.5,82.2,138.2,168.3,198.6,260.8c2.2-3.7,3.9-6.4,5.5-9.2
            c25.6-44,49.7-89,77-131.9c54.5-85.6,112-169,183.5-241.8c9.7-9.9,19.6-13.7,33.5-13.7c134.1,0.5,268.3,0.5,402.4,0.1
            c7.4,0,17.2-2.6,21.8-7.7c59.1-66.1,117.4-132.9,175.8-199.6c2.3-2.5,3.6-5.9,7-11.5H1528.9z M1431.6,480.8
            c1.7-9.3,4.2-18.6,4.3-27.9c0.2-17.1,8.3-21,29.2-14.7l0,0c-11.3,48.7-20.2,97.7-34.2,145.2c-39.1,133.2-111.8,248.3-200.7,353
            c-63.1,74.3-130.8,144.7-196.5,216.8c-1.4,1.6-3.3,2.7-5.7,4.7l-17.9-12.7c4.5-4.9,8.3-9.2,12.3-13.4
            c85.5-89.4,170.8-178.9,243.4-279.4C1346.9,740.1,1407,618.4,1431.6,480.8z M727.4,338.5c3.2-153.6,130.9-275.1,286.1-272.3
            c151.9,2.7,275.1,131.8,272,285c-3.1,155.5-131.2,277.3-288,273.9C845.7,621.8,724.1,492.8,727.4,338.5z M559.3,436.8h22.2
            c3.4,43.3,12.8,85.2,32.1,124.4C659.7,655,735.2,717.5,828,761c71.1,33.3,146.6,49.6,224.8,53.4c3.3,0.2,6.5,0.4,10.9,0.7l0,23.4
            c-36.8-4.7-72.6-7.3-107.6-14.1c-122-23.9-228.3-77.2-310.1-173.2C594,590,563.7,519.4,559.3,436.8z M969.7,850.1
            c-45.5,37.9-70.5,84.3-89.3,134.1c-86.8-20.8-257.1-227.3-268.3-328.4C709.8,762.7,824,831.6,969.7,850.1z M1736.7,1161
            c-134.7,0-269.4-0.2-404.1,0.4c-7.6,0-17.8,3.5-22.6,9c-83.2,96-162.4,195.1-229.4,303.3c-9,14.5-17.5,29.3-26.2,44
            c-1.5-0.5-2.8-0.5-3.2-1.2c-80.9-120-179.7-224.9-277.4-331c-25.3-27.4-49.9-37.6-86.9-36.3c-86.9,3.1-174.1,3.8-260.9-0.2
            c-49.9-2.3-100.3-11.5-148.6-24.7c-101.2-27.8-161-97.9-184-199c-8.1-35.4-11.3-71.9-17.2-110.3l23.2,0c2.8,24.8,4.1,49.1,8.3,72.9
            c25.1,140.4,97.5,211.8,239.7,231.6c55.8,7.8,112.6,10,169,11.2c61.5,1.3,123-1.5,184.5-2.6c4.2-0.1,8.5-0.6,10.7-0.8
            c-56.8-70.8-115.5-140.4-170.4-213.1s-96.9-153.1-130.1-239.1c16.6-4.3,25.9-1.7,32.9,15.1c53.7,129.5,133.9,242.2,226.5,346.2
            c76.3,85.7,156,168.3,231.7,254.5c47.4,54.1,90.3,112.2,135.1,168.5c4.4,5.6,8.4,11.5,12.9,17.7c1.9-1.2,3.6-1.8,4.3-2.9
            c78.1-132.3,174.1-251.3,273.9-367.5c75.5-87.9,150.7-176.2,212.8-274.4c29.3-46.3,55.9-94.3,82.3-142.4
            c7.8-14.2,15.8-20.1,32.2-14.2c-78.1,172.5-194.3,318.7-320.1,463.2h420.4c0.3,7.6,0.6,13.5,1,21.9H1736.7z"
          />
          <path
            d="M1006.7,89C865,88.8,749.3,204.6,749.6,346.2c0.4,141,115.4,256.1,256.4,256.1c141.1,0.1,256.6-114.7,257.2-255.4
	C1263.7,205.4,1148.1,89.2,1006.7,89z M1005.3,455.9c-60.3-0.7-108.6-50-108.4-111h0c0.2-60.9,49.1-109.1,110.2-108.6
	s108.9,49.1,108.4,110.4C1115,407.1,1065.2,456.5,1005.3,455.9z"
          />
        </svg>
      </router-link>
      <nav
        v-if="showNavigationItems"
        class="navigation__items"
        v-bind:class="{ active: navigationItemsVisible }"
      >
        <router-link
          v-for="item in navigationItems"
          :key="item.path"
          :to="item.path"
          class="navigation__item-link"
        >
          {{ item.name }}
        </router-link>
        <div class="navigation__item-link-signinup">
          <router-link
            class="navigation__item-link-signinup-item navigation__item-link"
            v-if="userRoles.length < 1"
            :to="'/inloggen'"
            >Inloggen
          </router-link>
          <router-link
            class="navigation__item-link-signinup-item navigation__item-link"
            v-if="userRoles.length < 1"
            :to="'/inschrijven'"
            >Inschrijven
          </router-link>
        </div>
      </nav>
      <div class="navigation__bar-buttons">
        <router-link
          class="button input__button navigation__bar-logoutbutton"
          v-if="userRoles.length < 1"
          :to="'/inloggen'"
        >
          Inloggen
        </router-link>
        <button
          type="button"
          class="button__transparent myprofile__icon-wrapper"
          v-if="userRoles.length > 0 && showMyAccountIcon"
          @click="displayMyProfilePopup = !displayMyProfilePopup"
          aria-label="Mijn OIJV"
        >
          <svg
            class="myprofile__icon"
            focusable="false"
            viewBox="2 2 20 20"
            aria-hidden="true"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
            ></path>
          </svg>
        </button>
        <div
          v-if="showMyAccountIcon"
          class="myprofile__popup"
          :class="{ active: displayMyProfilePopup }"
        >
          <span
            class="myprofile__popup-name"
            v-if="userRoles.includes('member')"
            >{{ firstname }} {{ lastname }}</span
          >
          <a
            :href="$router.resolve({ name: 'MyOIJV' }).href"
            v-if="userRoles.includes('member')"
            class="myprofile__popup-link"
            :to="'/mijn-accoount'"
          >
            <svg
              class="myprofile__popup-linkicon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
              ></path>
            </svg>
            Mijn OIJV
          </a>
          <router-link
            v-if="userRoles.includes('board')"
            class="myprofile__popup-link"
            :to="'/dashboard'"
          >
            <svg
              class="myprofile__popup-linkicon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
              ></path>
            </svg>
            Dashboard
          </router-link>
          <div style="display: flex">
            <button
              class="button input__button myprofile__popup-logout"
              @click="logOut"
            >
              Uitloggen
            </button>
          </div>
        </div>
        <div
          class="hamburger"
          v-bind:class="{ active: navigationItemsVisible }"
          @click="toggleNavigation"
          v-if="showNavigationItems"
        >
          <div class="hamburger__item"></div>
          <div class="hamburger__item"></div>
          <div class="hamburger__item"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    showNavigationItems: Boolean,
    showMyAccountIcon: Boolean,
  },
  setup() {
    const router = useRouter();
    // const errors = ref([]);
    const navigationItemsVisible = ref(false);
    const displayMyProfilePopup = ref(false);
    const userRoles = computed(() => {
      const raw = document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === "roles" ? decodeURIComponent(parts[1]) : r;
      }, "");

      if (!raw) return [];

      return JSON.parse(raw)
    });

    const firstname = ref("");
    const lastname = ref("");
    const navigationItems = [
      {
        name: "Home",
        path: "/",
      },
      {
        name: "Lidmaatschap",
        path: "/lidmaatschap",
      },
      {
        name: "Bestuur",
        path: "/bestuur",
      },
      {
        name: "Nieuws",
        path: "/nieuws",
      },
      /* {
        name: "Media",
        path: "/media",
      }, */
      /* {
        name: 'COVID-19',
        path: 'covid-19'
      }, */
      {
        name: 'OIJV en veiligheid',
        path: '/oijv-en-veiligheid'
      },
      {
        name: "Contact",
        path: "/contact",
      },
    ];

    window.addEventListener("click", hideMyProfile);

    function toggleNavigation() {
      this.navigationItemsVisible = !this.navigationItemsVisible;
    }

    function logOut() {
      axios({
        url: "/api/signout",
        method: "POST",
        withCredentials: true,
        headers: {
          "content-type": "application/json",
        },
      })
        .then(() => {
          router.push("/inloggen");
        })
        .catch(() => {});
    }

    function hideMyProfile(e) {
      if (!e) return;

      let clickOnPopup = false;

      if (typeof e.path == "object") {
        clickOnPopup = Object.keys(e.path).some((key) => {
          const el = e.path[key];
          if (!el.classList) return false;
          return (
            el.classList.contains("myprofile__popup") ||
            el.classList.contains("myprofile__icon-wrapper")
          );
        });
      } else {
        let el = e.target;
        while (el !== document.body && clickOnPopup == false && el) {
          if (
            el.classList.contains("myprofile__popup") ||
            el.classList.contains("myprofile__icon-wrapper")
          ) {
            clickOnPopup = true;
          }
          el = el.parentElement;
        }
      }

      if (!clickOnPopup) displayMyProfilePopup.value = false;
    }

    return {
      navigationItemsVisible,
      toggleNavigation,
      navigationItems,
      logOut,
      userRoles,
      displayMyProfilePopup,
      firstname,
      lastname,
    };
  },
};
</script>
