<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container" style="margin-bottom: 3rem">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <h1 class="dashboard__title">Reserveren</h1>
        <div class="dashboard__reservation-container">
          <span
            v-if="parsedTimeslots.length < 1"
            style="display: block; text-align: center; margin: 1rem auto"
            >Er zijn nog geen tijdsloten beschikbaar</span
          >
          <div
            class="reservation__day-container"
            v-for="day in parsedTimeslots"
            :key="day.index"
          >
            <span class="reservation__day-title">{{
              date.format(day.date, "dddd DD MMMM")
            }}</span>
            <div class="reservation__day-halfday-items">
              <div
                class="reservation__day-halfday-container"
                v-for="daypart in day.dayParts"
                :key="daypart.index"
              >
                <div
                  v-if="daypart.timeslots.length > 0"
                  class="reservation__day-halfday-content"
                >
                  <span class="reservation__day-halfday-title">{{
                    daypart.title
                  }}</span>
                  <div class="reservation__day-timeslot-grid-container">
                    <div
                      class="reservation__day-timeslot-container"
                      v-for="timeslot in daypart.timeslots"
                      :key="timeslot._id"
                    >
                      <div class="reservation__day-timeslot-info">
                        <span
                          v-if="timeslot.allowedMemberType == 'all'"
                          class="reservation__day-timeslot-time"
                          style="font-weight: 700; margin-bottom: 0.5rem"
                          >Iedereen</span
                        >
                        <span
                          v-if="timeslot.allowedMemberType == 'children'"
                          class="reservation__day-timeslot-time"
                          style="font-weight: 700; margin-bottom: 0.5rem"
                          >Kinderen (t/m 17 jaar)</span
                        >
                        <span
                          v-if="timeslot.allowedMemberType == 'adults'"
                          class="reservation__day-timeslot-time"
                          style="font-weight: 700; margin-bottom: 0.5rem"
                          >Volwassen (vanaf 18 jaar)</span
                        >
                        <span
                          v-if="timeslot.allowedMemberType == 'families'"
                          class="reservation__day-timeslot-time"
                          style="font-weight: 700; margin-bottom: 0.5rem"
                          >Families (kinderen t/m 15 jaar)</span
                        >
                      </div>
                      <div class="reservation__day-timeslot-info">
                        <span class="reservation__day-timeslot-time"
                          >{{ date.format(timeslot.startdate, "HH:mm") }} -
                          {{ date.format(timeslot.enddate, "HH:mm") }}</span
                        >
                        <span class="reservation__day-timeslot-spaces">
                          {{ timeslot.availableSpots }}
                          {{
                            timeslot.availableSpots != 1 ? "plekken" : "plek"
                          }}
                          vrij</span
                        >
                      </div>
                      <div
                        v-for="member in timeslot.availableMembers"
                        :key="member._id"
                      >
                        <div
                          v-if="
                            member.checkedIn ||
                            timeslot.allowedMemberType == 'all' ||
                            timeslot.allowedMemberType == 'families' ||
                            (timeslot.allowedMemberType == 'children' &&
                              member.memberType == 'child') ||
                            (timeslot.allowedMemberType == 'adults' &&
                              member.memberType == 'adult')
                          "
                          class="reservation__day-timeslot-item"
                        >
                          <label
                            class="checkbox"
                            style="margin-right: 0.5rem"
                            :class="{
                              disabled:
                                !member.checkedIn &&
                                (timeslot.availableSpots <= 0 ||
                                  day.dayParts.some(
                                    (daypart) =>
                                      daypart.timeslots.filter((ts) =>
                                        ts.availableMembers.some(
                                          (mb) =>
                                            mb._id == member._id && mb.checkedIn
                                        )
                                      ).length > 0
                                  )),
                            }"
                          >
                            <input
                              :id="timeslot._id + member._id"
                              :name="day.date + member._id"
                              value="true"
                              :checked="member.checkedIn"
                              class="checkbox__input"
                              :type="'checkbox'"
                              autocomplete="false"
                              :disabled="
                                !member.checkedIn &&
                                (timeslot.availableSpots <= 0 ||
                                  day.dayParts.some(
                                    (daypart) =>
                                      daypart.timeslots.filter((ts) =>
                                        ts.availableMembers.some(
                                          (mb) =>
                                            mb._id == member._id && mb.checkedIn
                                        )
                                      ).length > 0
                                  ))
                              "
                              @change="
                                updateTimeSlot(timeslot._id, member._id, $event)
                              "
                            />
                            <span class="checkbox__span"><span></span></span>
                          </label>

                          <label
                            style="user-select: none; cursor: pointer"
                            :for="timeslot._id + member._id"
                            >{{ member.firstname }} {{ member.lastname }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import axios from "axios";
import date from "date-and-time";

import { io } from "socket.io-client";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";
import { onMounted, ref, onUnmounted } from "vue";

export default {
  components: { NavigationBar, FooterComponent, DashboardNavigation },
  setup() {
    const errors = ref([]);
    const timeslots = ref([]);
    const parsedTimeslots = ref([]);
    const socket = io({ transports: ["websocket"], upgrade: false });

    getTimeSlots();

    onMounted(() => {
      try {
        socket.connect();
        socket.on("checkin", () => {
          getTimeSlots();
        });
      } catch (e) {
        handleError(e);
      }
    });

    onUnmounted(() => {
      socket.close();
    });

    async function getTimeSlots() {
      try {
        const resp = await axios({
          url: `/api/timeslots`,
          method: "GET",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        timeslots.value = resp.data;

        errors.value = [];

        parseTimeSlots();
      } catch (e) {
        handleError(e);
      }
    }

    async function updateTimeSlot(timeslotId, memberId, event) {
      try {
        const checkbox = event.target;

        await axios({
          url: `/api/timeslots/checkin?id=${timeslotId}`,
          method: "PUT",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
          data: {
            _id: memberId,
            participated: checkbox.checked,
          },
        });

        errors.value = [];

        // TODO: notification
      } catch (e) {
        event.target.checked = !event.target.checked;

        if (e.response && e.response.data && e.response.data.errors) {
          e.response.data.errors.map((error) => {
            // TODO: notification
          });
        } else {
          handleError(e);
        }
      }
    }

    function handleError(e) {
      if (e.response && e.response.data && e.response.data.errors) {
        errors.value = e &&
          e.response &&
          e.response.data &&
          e.response.data.errors && [...new Set(e.response.data.errors)];

        return;
      }

      fetch("/api/error", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          timeslots: timeslots.value,
          error: {
            string: e.toString(),
            msg: e.message,
            fileName: e.fileName,
            lineNumber: e.lineNumber,
          },
        }),
      });
    }

    function parseTimeSlots() {
      parsedTimeslots.value = [];

      timeslots.value.map((timeslot) => {
        if (
          !parsedTimeslots.value.some(
            (ts) =>
              new Date(ts.date).toDateString() ==
              new Date(timeslot.startdate).toDateString()
          )
        ) {
          parsedTimeslots.value.push({
            date: new Date(timeslot.startdate),
            dayParts: [
              {
                title: "Ochtend",
                timeslots: [],
              },
              {
                title: "Middag",
                timeslots: [],
              },
              {
                title: "Avond",
                timeslots: [],
              },
            ],
          });
        }

        const dateIndex = parsedTimeslots.value.findIndex(
          (ts) =>
            new Date(ts.date).toDateString() ==
            new Date(timeslot.startdate).toDateString()
        );

        const hours = new Date(timeslot.startdate).getHours();
        const minutes = new Date(timeslot.startdate).getMinutes();
        const timeSummed = Number(
          hours.toString() + (minutes < 10 ? "0" : "") + minutes.toString()
        );

        let dayPartIndex = 0;

        if (timeSummed > 1800) {
          dayPartIndex = 2;
        } else if (timeSummed > 1200) {
          dayPartIndex = 1;
        }

        parsedTimeslots.value[dateIndex].dayParts[dayPartIndex].timeslots.push(
          timeslot
        );
      });
    }

    return {
      errors,
      date,
      updateTimeSlot,
      parsedTimeslots,
    };
  },
};
</script>
