<template>
  <div class="page__container">
    <navigation-bar :showNavigationItems="true" :showMyAccountIcon="true" />
    <div class="page__content-container" style="flex: 1; display: flex">
      <h1 style="text-align: center; font-weight: 500; margin: auto">
        Pagina niet gevonden
      </h1>
    </div>
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: { NavigationBar, FooterComponent },
  setup() {
    return {
    };
  },
};
</script>