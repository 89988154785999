<template>
  <div class="page__container">
    <navigation-bar :showNavigationItems="true" :showMyAccountIcon="true" />
    <div class="page__content-container">
      <h1 class="page__title">Privacy verklaring</h1>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging, gevestigd aan J.J.Talsmalaan 11, is
        verantwoordelijk voor de verwerking van persoonsgegevens zoals
        weergegeven in deze privacyverklaring.<br /><br />
        Contactgegevens:<br />
        https://www.oijv.nl<br />
        J.J.Talsmalaan 11<br />
        <br />
        Oscar Rijpstra is de Functionaris Gegevensbescherming van Oosterbeekse
        IJsvereniging Hij/zij is te bereiken via
        oscarrijpstra@nolimitsoftware.nl
      </p>
      <h2 class="page__subtitle">Persoonsgegevens die wij verwerken</h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging verwerkt uw persoonsgegevens doordat u
        gebruik maakt van onze diensten en/of omdat u deze zelf aan ons
        verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die
        wij verwerken:<br />
        - Voor- en achternaam<br />
        - Geslacht<br />
        - Geboortedatum<br />
        - Geboorteplaats<br />
        - Adresgegevens<br />
        - Telefoonnummer<br />
        - E-mailadres<br />
        - Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een
        profiel op deze website aan te maken, in correspondentie en
        telefonisch<br />
        - Bankrekeningnummer
      </p>
      <h2 class="page__subtitle">
        Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
      </h2>
      <p class="page__content-text">
        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen
        over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming
        hebben van ouders of voogd. We kunnen echter niet controleren of een
        bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn
        bij de online activiteiten van hun kinderen, om zo te voorkomen dat er
        gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
        Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke
        gegevens hebben verzameld over een minderjarige, neem dan contact met
        ons op via info@oijv.nl, dan verwijderen wij deze informatie.
      </p>
      <h2 class="page__subtitle">
        Met welk doel en op basis van welke grondslag wij persoonsgegevens
        verwerken
      </h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging verwerkt uw persoonsgegevens voor de volgende
        doelen:<br />
        - Het afhandelen van uw betaling<br />
        - U te kunnen bellen of e-mailen indien dit nodig is om onze
        dienstverlening uit te kunnen voeren<br />
        - U de mogelijkheid te bieden een account aan te maken<br />
        - Om goederen en diensten bij u af te leveren
      </p>
      <h2 class="page__subtitle">Geautomatiseerde besluitvorming</h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging neemt niet op basis van geautomatiseerde
        verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen
        hebben voor personen. Het gaat hier om besluiten die worden genomen door
        computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld
        een medewerker van Oosterbeekse IJsvereniging) tussen zit.
      </p>
      <h2 class="page__subtitle">Hoe lang we persoonsgegevens bewaren</h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging bewaart uw persoonsgegevens niet langer dan
        strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden
        verzameld. Wij verwijderen alle persoonsgegevens wanneer uw lidmaatschap
        is verwijderd.
      </p>
      <h2 class="page__subtitle">Delen van persoonsgegevens met derden</h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging verstrekt geen persoonsgegevens aan derden en
        alleen als dit nodig is voor de uitvoering van onze overeenkomst met u
        of om te voldoen aan een wettelijke verplichting.
      </p>
      <h2 class="page__subtitle">
        Cookies, of vergelijkbare technieken, die wij gebruiken
      </h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging gebruikt alleen technische en functionele
        cookies. En analytische cookies die geen inbreuk maken op uw privacy.
        Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
        website wordt opgeslagen op uw computer, tablet of smartphone. De
        cookies die wij gebruiken zijn noodzakelijk voor de technische werking
        van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar
        behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook
        kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor
        cookies door uw internetbrowser zo in te stellen dat deze geen cookies
        meer opslaat. Daarnaast kunt u ook alle informatie die eerder is
        opgeslagen via de instellingen van uw browser verwijderen.
      </p>
      <h2 class="page__subtitle">Gegevens inzien, aanpassen of verwijderen</h2>
      <p class="page__content-text">
        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
        verwijderen. Dit kunt u zelf doen via de persoonlijke instellingen van
        uw account. Daarnaast heeft u het recht om uw eventuele toestemming voor
        de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door ons bedrijf en heeft u het recht
        op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek
        kunt indienen om de persoonsgegevens die wij van u beschikken in een
        computerbestand naar u of een ander, door u genoemde organisatie, te
        sturen. Wilt u gebruik maken van uw recht op bezwaar en/of recht op
        gegevensoverdraagbaarheid of heeft u andere vragen/opmerkingen over de
        gegevensverwerking, stuur dan een gespecificeerd verzoek naar
        info@oijv.nl. Om er zeker van te zijn dat het verzoek tot inzage door u
        is gedaan, vragen wij u een kopie van uw identiteitsbewijs bij het
        verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine
        readable zone, de strook met nummers onderaan het paspoort),
        paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming
        van uw privacy. Oosterbeekse IJsvereniging zal zo snel mogelijk, maar in
        ieder geval binnen vier weken, op uw verzoek reageren. Oosterbeekse
        IJsvereniging wil u er tevens op wijzen dat u de mogelijkheid hebt om
        een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
        Persoonsgegevens. Dat kan via de volgende link:
        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
      </p>
      <h2 class="page__subtitle">Hoe wij persoonsgegevens beveiligen</h2>
      <p class="page__content-text">
        Oosterbeekse IJsvereniging neemt de bescherming van uw gegevens serieus
        en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang,
        ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als
        u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er zijn
        aanwijzingen van misbruik, neem dan contact op met onze klantenservice
        of via info@oijv.nl. Oosterbeekse IJsvereniging heeft de volgende
        maatregelen genomen om uw persoonsgegevens te beveiligen:<br />
        - TLS (voorheen SSL) Wij versturen uw gegevens via een beveiligde
        internetverbinding. Dit kunt u zien aan de adresbalk 'https' en het
        hangslotje in de adresbalk.
      </p>
    </div>
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: { NavigationBar, FooterComponent },
  setup() {
    return {};
  },
};
</script>