<template>
  <div class="qrScannerContainer">
    {{ result }}
    <div v-for="error in errors" :key="error">
      <p class="qrScannerError">{{ error }}</p>
    </div>
    <svg
      class="qrScannerStatusIcon qrScannerStatusIcon-success"
      :class="{ 'qrScannerStatusIcon-active': errors.length < 1 && result }"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      ></path>
    </svg>
    <svg
      class="qrScannerStatusIcon qrScannerStatusIcon-error"
      :class="{ 'qrScannerStatusIcon-active': errors.length > 0 }"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      ></path>
    </svg>
    <qrcode-stream @decode="checkQR" :track="paintOutline" @init="onInit" />
  </div>
</template>

<script>
import axios from "axios";
import { QrcodeStream } from "vue3-qrcode-reader";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return { error: "", result: "", status: "", errors: [] };
  },
  methods: {
    paintOutline(detectedCode, ctx) {
      const {
        topLeftCorner,
        topRightCorner,
        bottomLeftCorner,
        bottomRightCorner,
      } = detectedCode;

      ctx.strokeStyle = "#e53935";
      ctx.lineWidth = 3;

      ctx.beginPath();
      ctx.moveTo(topLeftCorner.x, topLeftCorner.y);
      ctx.lineTo(topRightCorner.x, topRightCorner.y);
      ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y);
      ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y);
      ctx.closePath();
      ctx.stroke();
    },

    checkQR(result) {
      try {
        this.result = result;

        const { id, type } = JSON.parse(result)

        axios(`/api/check-in`, {
            method: 'POST',
            data: {
              id: id,
              type: type
            }
          })
          .then(() => {
            setTimeout(() => (this.result = ""), 5000);
          })
          .catch(({ response, message }) => {
            if (response?.data?.errors) {
              this.errors = response.data.errors;
            } else {
              this.errors = [message];
            }

            setTimeout(() => ((this.errors = []), (this.result = "")), 5000);
          });

          console.log(id)


      } catch (e) {
        console.log(e);
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
};
</script>
