<template>
  <div class="minheight__wrapper">
    <navigation-bar />
    <div class="content__centered content__centered-setup">
      <div class="setup__container content__centered-container">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <div class="setup__steps">
          <div class="setup__step">
            <span class="setup__step-text">Stap 1: Account</span>
          </div>
          <div class="setup__step">
            <span class="setup__step-text">Stap 2: Familieleden</span>
          </div>
          <div class="setup__step selected">
            <span class="setup__step-text">Stap 3: Betaling</span>
          </div>
        </div>
        <div class="payment-validation__container-content">
          <div v-if="paymentStatus == 'open'">
            <div
              class="spinner payment-validation__spinner"
            >
              <svg class="spinner__svg" viewBox="22 22 44 44">
                <circle
                  class="spinner__svg-inner"
                  cx="44"
                  cy="44"
                  r="20.2"
                  fill="none"
                  stroke-width="3.6"
                ></circle>
              </svg>
            </div>
            <p class="payment-validation__text">
              De betaling wordt gecontroleerd
            </p>
          </div>
          <div v-if="paymentStatus == 'paid'">
            <p class="payment-validation__text">
              <svg
                class="
                  payment-validation__icon payment-validation__icon-success
                "
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                ></path>
              </svg>
              Uw account is succesvol ingesteld
            </p>
          </div>
          <div
            v-if="
              paymentStatus == 'expired' ||
              paymentStatus == 'failed' ||
              paymentStatus == 'canceled'
            "
          >
            <svg
              class="payment-validation__icon payment-validation__icon-failed"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              tabindex="-1"
              title="Close"
            >
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              ></path>
            </svg>
            <div v-if="paymentStatus == 'expired'">
              <p class="payment-validation__text">De betaling is verlopen</p>
            </div>
            <div
              v-if="paymentStatus == 'failed' || paymentStatus == 'canceled'"
            >
              <p class="payment-validation__text">De betaling is mislukt</p>
            </div>
            <button
              class="button input__button payment-validation__retry"
              @click="retryPayment"
            >
              Opnieuw proberen
            </button>
          </div>
        </div>
        <div
          class="setup__buttons-container"
          style="padding: 0.5rem"
          v-if="paymentStatus != 'paid' && !setupDone"
        >
          <router-link
            :to="'/mijn-account/setup'"
            class="button input__button submit__buttons-step"
          >
            <svg
              style="transform: rotate(180deg); margin-left: 0"
              class="submit__buttons-step-icon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
              ></path>
            </svg>
            Terug
          </router-link>
        </div>
        <div
          class="setup__buttons-container"
          style="padding: 0.5rem"
          v-if="paymentStatus == 'paid'"
        >
          <span></span>
          <router-link
            class="button input__button submit__buttons-step"
            :to="'/mijn-account'"
          >
            Naar mijn OIJV
            <svg
              class="submit__buttons-step-icon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
              ></path>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import axios from "axios";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: { NavigationBar, FooterComponent },
  setup() {
    const errors = ref([]);
    const paymentId = ref("");
    const paymentStatus = ref("open");
    const interval = ref({});

    const setupDone = computed(() => {
      return document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === "setup_done" ? decodeURIComponent(parts[1]) : r;
      }, "") == 'true';
    });

    const getPaymentStatus = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        paymentId.value = urlParams.get("id");

        const { data } = await axios({
          url: `/api/payments/${paymentId.value}`,
          method: "GET",
          withCredentials: true,
        });

        paymentStatus.value = data.status;

        if (data.status !== "open") {
          clearInterval(interval.value);
        }
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const retryPayment = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/payment",
          method: "POST",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        window.location = data.redirectUrl;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const checkInterval = () => {
      getPaymentStatus();

      interval.value = setInterval(() => {
        getPaymentStatus();
      }, 1000);
    };

    checkInterval();

    return {
      errors,
      paymentStatus,
      retryPayment,
      setupDone
    };
  },
};
</script>
