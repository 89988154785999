<template>
  <div class="dashboard__item-container">
    <h2 class="dashboard__tile-title">Leden</h2>
    <Line v-if="loaded" :options="options" :data="data" style="max-height: 200px" />
    <span class="dashboard__tile-amount">{{
      membersCount.members + membersCount.submembers
    }}</span>
    <span class="dashboard__tile-sub"
      >{{ membersCount.members }} hoofdleden</span
    >
    <span class="dashboard__tile-sub"
      >{{ membersCount.submembers }} familieleden</span
    >
  </div>
</template>

<script>
import axios from "axios";
import date from "date-and-time";
import { reactive, ref } from "vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

export default {
  components: { Line },
  setup() {
    const loaded = ref(false);
    const membersCount = ref({
      members: 0,
      submembers: 0,
      memberCountPerDay: [],
    });
    const data = ref({
      labels: [],
      datasets: [
        {
          label: "Leden",
          backgroundColor: "#e53935",
          borderColor: "#e53935",
          data: [],
        },
      ],
    });

    const getTotalMemberCounts = async () => {
      try {
        const { data: respData } = await axios({
          url: `/api/dashboard/member-count`,
          method: "GET",
        });

        membersCount.value = respData;
        data.value.labels = respData.memberCountPerDay.map((day) => day.date);
        data.value.datasets[0].data = respData.memberCountPerDay.map(
          (day) => day.amount
        );

        loaded.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    getTotalMemberCounts();

    return {
      membersCount,
      data,
      options,
      loaded
    };
  },
};
</script>