<template>
  <div>
    <form
      class="popup__wrapper"
      method="POST"
      v-on:submit.prevent="submitPayContribution"
    >
      <div class="popup__box" style="max-width: 30rem">
        <h2 class="popup__title">Contributie betalen</h2>
        <p class="popup__text">
          Openstaand bedrag van €{{ parseCosts(totalContribution) }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>
const parseCosts = (costs) => {
  if (!String(costs)) return;
  return Number(costs).toFixed(2).replace(/\./g, ",");
};

import { ref } from "@vue/reactivity";
import axios from 'axios';
export default {
  setup() {
    const contributionPaid = ref({});

    const getContributionPaid = async () => {
      try {
        const { data } = await axios({
          url: "/api/my-account/contribution-paid",
          method: "GET",
          withCredentials: true,
        });

        contributionPaid.value = data;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    getContributionPaid();

    return {
      contributionPaid,
      parseCosts
    };
  },
};
</script>
