<template>
  <div>
    <form
      class="popup__wrapper"
      method="POST"
      v-on:submit.prevent="paymentCheck"
    >
      <div class="popup__box" style="max-width: 30rem">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <h2 class="popup__title">
          Familielid {{ submember._id ? "bewerken" : "toevoegen" }}
        </h2>
        <div class="setup__formgrid setup__formgrid-11">
          <div>
            <label class="label label-required">Voornaam</label>
            <input
              class="input input__text input__fw"
              type="text"
              placeholder="voornaam"
              autocomplete="false"
              v-model="submember.firstname"
              required
              pattern="[a-zA-Z\.\-_ ]{1,20}"
            />
          </div>
          <div>
            <label class="label label-required">Achternaam</label>
            <input
              class="input input__text input__fw"
              type="text"
              placeholder="achternaam"
              autocomplete="false"
              v-model="submember.lastname"
              required
              pattern="[a-zA-Z\-_ ]{3,30}"
            />
          </div>
        </div>
        <div class="setup__formgrid setup__formgrid-11">
          <div>
            <label class="label label-required">Geboortedatum</label>
            <input
              class="input input__date input__fw input__date-nocalander"
              :type="dateInputSupported ? 'date' : 'text'"
              placeholder="DD-MM-JJJJ"
              autocomplete="false"
              v-model="submember.parsedBirthdate"
              required
              pattern="[0-9]{2}-[0-9]{2}-([19]|[20]){2}[0-9]{2}"
            />
          </div>
          <div>
            <label class="label label-required">Geslacht</label>
            <select
              class="input input__select input__fw"
              v-model="submember.gender"
              autocomplete="false"
              required
            >
              <option value="male">Man</option>
              <option value="female">Vrouw</option>
            </select>
          </div>
        </div>
        <div class="members__toolbar">
          <button
            class="button button input__button"
            @click="closePopup"
            type="button"
          >
            Annuleren
          </button>
          <button
            class="button input__button submit__buttons-step"
            type="submit"
          >
            {{ submember._id ? "Aanpassen" : "Toevoegen" }}
          </button>
        </div>
      </div>
    </form>
    <form class="popup__wrapper" v-if="paymentPopupVisible" method="POST" v-on:submit.prevent="addUpdateSubmember">
      <div class="popup__box" style="max-width: 30rem">
        <h2 class="popup__title">Kosten extra familielid</h2>
        <p class="popup__text" style="font-weight: 400">
          Bij het toevoegen van dit familielid worden de volgende kosten in
          rekening gebracht via een automatische incasso:<br />
          Contributie: €{{ parseCosts(extraMemberCosts) }}<br />
          Inschrijfkosten: €{{ parseCosts(extraRegistrationFee) }}
        </p>
        <div
          style="display: flex; flex-direction: row; justify-content: center"
        >
          <button
            class="button button input__button"
            @click="paymentPopupVisible = false"
          >
            Annuleren
          </button>
          <button
            style="margin-left: 0.5rem"
            class="button input__button submit__buttons-step"
            type="submit"
          >
            Doorgaan
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import date from "date-and-time";
import { onMounted, ref, computed } from "vue";

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    selectedSubmember: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const dateInputSupported = checkDateInputIsSupported();
    const errors = ref([]);
    const submember = ref({});
    const paymentPopupVisible = ref(false);
    const extraMemberCosts = ref(0);
    const extraRegistrationFee = ref(0);

    onMounted(() => {
      if (props.selectedSubmember) {
        submember.value = JSON.parse(JSON.stringify(props.selectedSubmember));

        submember.value.parsedBirthdate =
          submember.value.birthdate &&
          date.format(
            new Date(submember.value.birthdate),
            dateInputSupported ? "YYYY-MM-DD" : "DD-MM-YYYY"
          );
      } else {
        submember.value = {};
      }
    });

    const setupDone = computed(() => {
      return (
        document.cookie.split("; ").reduce((r, v) => {
          const parts = v.split("=");
          return parts[0] === "setup_done" ? decodeURIComponent(parts[1]) : r;
        }, "") == "true"
      );
    });

    const closePopup = () => {
      emit("update:visible", false);
    };

    const addUpdateSubmember = async () => {
      try {
        submember.value.birthdate = parseStringToDate(
          submember.value.parsedBirthdate,
          dateInputSupported ? "yyyy-MM-dd" : "dd-MM-yyyy"
        );

        await axios({
          url: `/api/my-account/submembers${
            submember.value._id ? `/${submember.value._id}` : ""
          }`,
          method: submember.value._id ? "PUT" : "POST",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
          data: {
            firstname: submember.value.firstname,
            lastname: submember.value.lastname,
            birthdate: submember.value.birthdate,
            gender: submember.value.gender,
          },
        });

        emit("update-submember");

        errors.value = [];

        // TODO: notification

        closePopup();
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const paymentCheck = async (event) => {
      try {
        const form = event.target;

        if (!form.checkValidity()) {
          return form.reportValidity();
        }

        if (!setupDone.value || submember.value._id) {
          return addUpdateSubmember();
        }

        submember.value.birthdate = parseStringToDate(
          submember.value.parsedBirthdate,
          dateInputSupported ? "yyyy-MM-dd" : "dd-MM-yyyy"
        );

        const { data } = await axios({
          url: `/api/my-account/calculate-contribution?newbirthdate=${submember.value.birthdate}`,
          method: "GET",
          withCredentials: true,
        });

        const contributionDifference =
          (data.newCosts.totalContribution + data.newCosts.registrationFee) - (data.currentCosts.totalContribution + data.currentCosts.registrationFee);

        if (contributionDifference <= 0) {
          return addUpdateSubmember();
        }

        extraMemberCosts.value =
          data.newCosts.totalContribution - data.currentCosts.totalContribution;
        extraRegistrationFee.value =
          data.newCosts.registrationFee - data.currentCosts.registrationFee;

        paymentPopupVisible.value = true;
      } catch ({ response, message }) {
        if (response.data?.errors) {
          errors.value = response.data.errors;
        } else {
          errors.value = [message];
        }
      }
    };

    const parseCosts = (costs) => {
      if (!String(costs)) return;
      return Number(costs).toFixed(2).replace(/\./g, ",");
    };

    return {
      errors,
      dateInputSupported,
      closePopup,
      submember,
      addUpdateSubmember,
      paymentCheck,
      paymentPopupVisible,
      extraMemberCosts,
      extraRegistrationFee,
      parseCosts,
    };
  },
};

function checkDateInputIsSupported() {
  const isOpera =
    !!window.opr || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
  const isFirefox = typeof InstallTrigger !== "undefined";
  const isChrome = navigator.userAgentData?.brands?.some(
    (b) => b.brand === "Google Chrome"
  );

  return isOpera || isFirefox || isChrome;
}

function parseStringToDate(string = "", format) {
  try {
    switch (format) {
      case "yyyy-MM-dd": {
        const day = string.split("-")[2];
        const month = string.split("-")[1];
        const year = string.split("-")[0];
        return new Date(`${year}-${month}-${day}`);
      }

      case "dd-MM-yyyy": {
        const day = string.split("-")[0];
        const month = string.split("-")[1];
        const year = string.split("-")[2];
        return new Date(`${year}-${month}-${day}`);
      }

      default:
        throw new Error("Format invalid");
    }
  } catch (e) {
    console.error(e);
  }
}
</script>
