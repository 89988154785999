<template>
  <div class="minheight__wrapper">
    <navigation-bar :showMyAccountIcon="true" />
    <div class="dashboard__container">
      <dashboard-navigation />
      <div class="dashboard__main-container">
        <div class="login__errors" v-if="errors.length">
          <span
            class="login__errors-item"
            v-for="error in errors"
            :key="error"
            >{{ error }}</span
          >
        </div>
        <h1 class="dashboard__title">Tijdsloten overzicht</h1>
        <div class="dashboard__toolbar">
          <!-- <router-link
            :to="'/reserveren/toevoegen'"
            class="button input__button dashboard__toolbar-button"
          >
            Tijdslot toevoegen
          </router-link> -->
        </div>
        <div
          class="reservation__day-container"
          v-for="day in parsedTimeslots"
          :key="day.index"
        >
          <span class="reservation__day-title">{{
            date.format(day.date, "dddd DD MMMM")
          }}</span>
          <div class="reservation__day-halfday-items">
            <div
              class="reservation__day-halfday-container"
              v-for="daypart in day.dayParts"
              :key="daypart.index"
            >
              <div
                v-if="daypart.timeslots.length > 0"
                class="reservation__day-halfday-content"
              >
                <span class="reservation__day-halfday-title">{{
                  daypart.title
                }}</span>
                <div class="reservation__day-timeslot-grid-container">
                  <div
                    class="reservation__day-timeslot-container"
                    v-for="timeslot in daypart.timeslots"
                    :key="timeslot._id"
                  >
                    <div class="reservation__day-timeslot-info">
                      <span
                        v-if="timeslot.allowedMemberType == 'all'"
                        class="reservation__day-timeslot-time"
                        style="font-weight: 700; margin-bottom: 0.5rem"
                        >Iedereen</span
                      >
                      <span
                        v-if="timeslot.allowedMemberType == 'children'"
                        class="reservation__day-timeslot-time"
                        style="font-weight: 700; margin-bottom: 0.5rem"
                        >Kinderen (t/m 17 jaar)</span
                      >
                      <span
                        v-if="timeslot.allowedMemberType == 'adults'"
                        class="reservation__day-timeslot-time"
                        style="font-weight: 700; margin-bottom: 0.5rem"
                        >Volwassen (vanaf 18 jaar)</span
                      >
                      <span
                        v-if="timeslot.allowedMemberType == 'families'"
                        class="reservation__day-timeslot-time"
                        style="font-weight: 700; margin-bottom: 0.5rem"
                        >Families (kinderen t/m 15 jaar)</span
                      >
                    </div>
                    <div class="reservation__day-timeslot-info">
                      <span class="reservation__day-timeslot-time"
                        >{{ date.format(timeslot.startdate, "HH:mm") }} -
                        {{ date.format(timeslot.enddate, "HH:mm") }}</span
                      >
                      <div style="display: flex; align-items: center">
                        <span class="reservation__day-timeslot-spaces">
                          {{ timeslot.checkedInMembersCount }} /
                          {{ timeslot.maxMembers }}</span
                        >
                        <router-link
                          :to="`/tijdsloten/${timeslot._id}`"
                          style="display: block; line-height: 1"
                        >
                          <svg
                            class="reservation__day-timeslot-persons"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                            ></path>
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    <div
                      v-for="member in timeslot.availableMembers"
                      :key="member._id"
                    >
                      <div
                        v-if="
                          member.checkedIn ||
                          timeslot.allowedMemberType == 'all' ||
                          timeslot.allowedMemberType == 'families' ||
                          (timeslot.allowedMemberType == 'children' &&
                            member.memberType == 'child') ||
                          (timeslot.allowedMemberType == 'adults' &&
                            member.memberType == 'adult')
                        "
                        class="reservation__day-timeslot-item"
                      >
                        <label
                          class="checkbox"
                          style="margin-right: 0.5rem"
                          :class="{
                            disabled:
                              !member.checkedIn &&
                              (timeslot.availableSpots <= 0 ||
                                day.dayParts.some(
                                  (daypart) =>
                                    daypart.timeslots.filter((ts) =>
                                      ts.availableMembers.some(
                                        (mb) =>
                                          mb._id == member._id && mb.checkedIn
                                      )
                                    ).length > 0
                                )),
                          }"
                        >
                          <input
                            :id="timeslot._id + member._id"
                            :name="day.date + member._id"
                            value="true"
                            :checked="member.checkedIn"
                            class="checkbox__input"
                            :type="'checkbox'"
                            autocomplete="false"
                            :disabled="
                              !member.checkedIn &&
                              (timeslot.availableSpots <= 0 ||
                                day.dayParts.some(
                                  (daypart) =>
                                    daypart.timeslots.filter((ts) =>
                                      ts.availableMembers.some(
                                        (mb) =>
                                          mb._id == member._id && mb.checkedIn
                                      )
                                    ).length > 0
                                ))
                            "
                            @change="
                              updateTimeSlot(timeslot._id, member._id, $event)
                            "
                          />
                          <span class="checkbox__span"><span></span></span>
                        </label>

                        <label
                          style="user-select: none; cursor: pointer"
                          :for="timeslot._id + member._id"
                          >{{ member.firstname }} {{ member.lastname }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
  </div>
</template>
<script>
import { onMounted, ref, onUnmounted } from "vue";
import axios from "axios";
import date from "date-and-time";

import { io } from "socket.io-client";

import NavigationBar from "@/components/NavigationBar.vue";
import FooterComponent from "@/components/Footer.vue";
import DashboardNavigation from "@/components/DashboardNavigation.vue";

export default {
  components: { NavigationBar, FooterComponent, DashboardNavigation },
  setup() {
    const errors = ref([]);
    const timeslots = ref([]);
    const parsedTimeslots = ref([]);
    const socket = io({ transports: ["websocket"], upgrade: false });

    getTimeSlots();

    onMounted(() => {
      try {
        socket.connect();
        socket.on("checkin", () => {
          getTimeSlots();
        });
      } catch (e) {
        console.log(e);
      }
    });

    onUnmounted(() => {
      socket.close();
    });

    async function getTimeSlots() {
      try {
        const resp = await axios({
          url: `/api/timeslots`,
          method: "GET",
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

        timeslots.value = resp.data;

        errors.value = [];

        parseTimeSlots();
      } catch (e) {
        handleError(e);
      }
    }

    function handleError(e) {
      if (e.response && e.response.data && e.response.data.errors) {
        errors.value = e &&
          e.response &&
          e.response.data &&
          e.response.data.errors && [...new Set(e.response.data.errors)];

        return;
      }

      fetch("/api/error", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          error: {
            string: e.toString(),
            msg: e.message,
            fileName: e.fileName,
            lineNumber: e.lineNumber,
          },
        }),
      });
    }

    function parseTimeSlots() {
      parsedTimeslots.value = [];

      timeslots.value.map((timeslot) => {
        timeslot.checkedInMembersCount =
          (timeslot.members && timeslot.members.length) || 0;

        if (
          !parsedTimeslots.value.some(
            (ts) =>
              new Date(ts.date).toDateString() ==
              new Date(timeslot.startdate).toDateString()
          )
        ) {
          parsedTimeslots.value.push({
            date: new Date(timeslot.startdate),
            dayParts: [
              {
                title: "Ochtend",
                timeslots: [],
              },
              {
                title: "Middag",
                timeslots: [],
              },
              {
                title: "Avond",
                timeslots: [],
              },
            ],
          });
        }

        const dateIndex = parsedTimeslots.value.findIndex(
          (ts) =>
            new Date(ts.date).toDateString() ==
            new Date(timeslot.startdate).toDateString()
        );

        const hours = new Date(timeslot.startdate).getHours();
        const minutes = new Date(timeslot.startdate).getMinutes();
        const timeSummed = Number(
          hours.toString() + (minutes < 10 ? "0" : "") + minutes.toString()
        );

        let dayPartIndex = 0;

        if (timeSummed > 1800) {
          dayPartIndex = 2;
        } else if (timeSummed > 1200) {
          dayPartIndex = 1;
        }

        parsedTimeslots.value[dateIndex].dayParts[dayPartIndex].timeslots.push(
          timeslot
        );
      });
    }

    return {
      errors,
      date,
      parsedTimeslots,
    };
  },
};
</script>
